<template>
  <div class="unreadMeters">
    <v-card>
      <v-card-title>
        <v-container>
          <v-row>
            <v-col class="col-md-3">
              <refresh-button
                :loading="isUnreadMetersRefreshing"
                @click="refreshUnreadMeters()"
              />
            </v-col>
            <v-col class="col-md-6">
              <table-filter
                :table-name="'unread meters'"
                :show-date-filter="false"
                :show-payment-status-filter="false"
                :show-month-filter="true"
                :show-year-filter="false"
                :label="filterLabel"
                @filter="applyFilters"
              />
            </v-col>
          </v-row>
          <v-row
            justify="center"
            class="mb-0"
          >
            <v-col class="col-md-6 text-center">
              <search-input
                v-model="search"
                :filters="searchFilters"
                :active-filter="activeSearchFilter"
                @filter-change="onSearchFilterChange"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="unreadMeters"
        :loading="isUnreadMetersLoading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:progress>
          <v-progress-linear
            indeterminate
            class="mt-2"
            rounded
          ></v-progress-linear>
          <p class="text-center mt-1">
            Loading...Please wait
          </p>
        </template>
        <template
          v-slot:body="{
            items,
          }"
        >
          <tbody v-if="unreadMeters.length > 0">
            <tr
              v-for="unreadMeter in items"
              :key="unreadMeter.id"
              :class="{ clickable: clickableRows }"
              v-on="clickableRows ? { click: () => onUnreadMeterClick(unreadMeter.id) } : {}"
            >
              <td v-if="clickableRows">
                <span
                  v-if="unreadMeter.user"
                  class="primary--text font-weight-medium"
                >{{ unreadMeter.user.account_number }}</span>
              </td>
              <td>{{ unreadMeter.meter.number }}</td>
              <td v-if="clickableRows">
                <span
                  v-if="unreadMeter.user"
                  class="primary--text font-weight-medium"
                >{{ unreadMeter.user.name }}</span>
              </td>
              <td>{{ unreadMeter.month | formatMonth }}</td>
              <td>{{ unreadMeter.reason }}</td>
              <td @click.stop>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      ma-2
                      v-bind="attrs"
                      text
                      icon
                      @click="editMeter(unreadMeter)"
                      v-on="on"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiPencil }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      ma-2
                      v-bind="attrs"
                      text
                      icon
                      @click="showDeleteMeterDialog(unreadMeter.id)"
                      v-on="on"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiDelete }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-show="!isUnreadMetersLoading">
              <td
                :colspan="headers.length"
                style="text-align: center"
              >
                No meter unreadMeters found
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-divider />
      <data-table-pagination
        :page="pagination.page"
        :page-count="pagination.pageCount"
        @page-change="onPageChange"
        @items-per-page-change="onItemsPerPageChange"
      />
    </v-card>
    <add-unread-meter-dialog
      :show-dialog="showAddMeterDialog"
      :unread-meter="meterReading"
      @close="closeAddMeterDialog()"
      @unread-meter-added="onUnreadMeterAdded"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteUnreadMeter()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import {
  mdiMagnify, mdiPencil, mdiSend, mdiDelete, mdiEye,
} from '@mdi/js'
import hasPermission from '@/mixins/hasPermission'
import printHtml from '@/mixins/printHtml'
import AddUnreadMeterDialog from '@/components/dialogs/AddUnreadMeterDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import SearchInput from '@/components/partials/SearchInput.vue'
import TableFilter from '../partials/table-filter/index.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import defaultMonthFilterDate from '@/mixins/defaultMonthFilterDate'
import DataTablePagination from '@/components/partials/DataTablePagination.vue'

export default {
  components: {
    AddUnreadMeterDialog,
    ConfirmDialog,
    SearchInput,
    TableFilter,
    RefreshButton,
    DataTablePagination,
  },
  mixins: [hasPermission, defaultMonthFilterDate, printHtml],
  props: {
    meterReadingUrl: {
      type: String,
      default: null,
    },
    clickableRows: {
      type: Boolean,
      default: true,
    },
    showAddMeterDialog: {
      type: Boolean,
      default: false,
    },
    forSpecificCustomer: {
      type: Boolean,
      default: false,
    },
    defaultSortBy: {
      type: String,
      default: 'account_number',
    },
    defaultSortDesc: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showConfirmDeleteDialog: false,
      showConfirmResendDialog: false,
      showPreviewUnreadMetersMessageDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      isConfirmResendDialogButtonLoading: false,
      toDelete: null,
      sortBy: [this.defaultSortBy],
      sortDesc: [this.defaultSortDesc],
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
      search: '',
      headers: [
        {
          text: 'Account Number',
          value: 'account_number',
          sortable: false,
        },
        {
          text: 'Meter Number',
          align: 'start',
          value: 'number',
          sortable: false,
        },
        {
          text: 'Customer',
          value: 'user',
          sortable: false,
        },
        {
          text: 'Month',
          value: 'month',
          sortable: false,
        },
        { text: 'Reason', value: 'reason', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      activeSearchFilter: 'users.account_number',
      searchFilters: [
        {
          text: 'Account number',
          value: 'users.account_number',
        },
        {
          text: 'Meter number',
          value: 'meters.number',
        },
        {
          text: 'Customer',
          value: 'users.name',
        },
      ],
      icons: {
        mdiMagnify,
        mdiPencil,
        mdiSend,
        mdiDelete,
        mdiEye,
      },
      meterReading: {},
      unreadMeters: [],
      isUnreadMetersLoading: true,
      isUnreadMetersRefreshing: false,
      isGetUnreadMetersPreviewMessageLoading: false,
      toResend: '',
      toResendMonth: '',
      toResendCustomerName: '',
      filterLabel: '',
      filters: {
        year: '',
        month: '',
        paymentStatus: [],
      },
      unreadMetersPreviewMessage: '',
      totalExpectedAmount: 0,
      totalAmountPaid: 0,
    }
  },
  computed: {
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.meterReadingUrl}|${this.search}|${this.sortBy}|${this.sortDesc}`
    },
  },
  watch: {
    refreshTable() {
      this.getUnreadMeters()
    },
    activeSearchFilter() {
      if (this.search !== '') {
        this.getUnreadMeters()
      }
    },
  },
  mounted() {
    if (this.forSpecificCustomer) {
      this.filters.year = new Date().getFullYear()
    } else {
      this.filters.month = this.defaultMonthDate.format('YYYY-MM')
    }
    this.getUnreadMeters()
    if (!this.clickableRows) {
      this.removeFirstTwoTableHeader()
    }
  },
  methods: {
    showResendUnreadMeterDialog(id, name, month) {
      this.showConfirmResendDialog = true
      this.toResend = id
      this.toResendMonth = month
      this.toResendCustomerName = name
    },
    getUnreadMeters: _.debounce(function () {
      this.isUnreadMetersLoading = true
      const status = JSON.stringify(this.filters.paymentStatus)
      axios
        .get(
          `${this.meterReadingUrl}&month=${this.filters.month}&year=${this.filters.year}&sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&search=${this.search}&search_filter=${this.activeSearchFilter}&status=${status}&page=${this.pagination.page}&perPage=${this.pagination.itemsPerPage}`,
        )
        .then(response => {
          this.unreadMeters = response.data.data
          this.pagination.page = response.data.current_page
          this.pagination.pageCount = response.data.last_page
          this.isUnreadMetersLoading = false
          this.isUnreadMetersRefreshing = false
        })
        .catch(error => {
          this.isUnreadMetersLoading = false
          this.isUnreadMetersRefreshing = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    onUnreadMeterAdded(month) {
      if (month !== this.filters.month) {
        this.filters.month = month
        this.filterLabel = month
      }
      this.refreshUnreadMeters()
    },
    refreshUnreadMeters() {
      this.isUnreadMetersRefreshing = true
      this.getUnreadMeters()
    },
    editMeter(meterReading) {
      this.meterReading = meterReading
      this.$emit('add-meter-dialog-open')
    },
    showDeleteMeterDialog(id) {
      this.showConfirmDeleteDialog = true
      this.toDelete = id
    },
    deleteUnreadMeter() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios
        .delete(`unread-meters/${this.toDelete}`)
        .then(() => {
          this.$notification.success('Meter unreadMeter deleted successfully')
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
          this.getUnreadMeters()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
        })
    },
    onUnreadMeterClick(meterReadingId) {
      this.$router.push({ name: 'unread-meters-details', params: { id: meterReadingId } })
    },
    closeAddMeterDialog() {
      this.meterReading = {}
      this.$emit('add-meter-dialog-close')
    },
    removeFirstTwoTableHeader() {
      this.headers.splice(0, 2)
    },
    onPageChange(page) {
      this.pagination.page = page
      this.getUnreadMeters()
    },
    onItemsPerPageChange(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage
      this.getUnreadMeters()
    },
    onSearchFilterChange(filter) {
      this.activeSearchFilter = filter
    },
    applyFilters(filters) {
      this.filters = filters
      this.getUnreadMeters()
    },
    calculateBalance(amountPaid, creditApplied, bill) {
      const amount = parseFloat(amountPaid) || 0
      const credit = parseFloat(creditApplied) || 0
      const balance = parseFloat(bill) - (amount + credit)

      if (balance < 0) {
        return 0
      }

      return balance
    },
  },
}
</script>

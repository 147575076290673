var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"unreadMeters"},[_c('v-card',[_c('v-card-title',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"col-md-3"},[_c('refresh-button',{attrs:{"loading":_vm.isUnreadMetersRefreshing},on:{"click":function($event){return _vm.refreshUnreadMeters()}}})],1),_c('v-col',{staticClass:"col-md-6"},[_c('table-filter',{attrs:{"table-name":'unread meters',"show-date-filter":false,"show-payment-status-filter":false,"show-month-filter":true,"show-year-filter":false,"label":_vm.filterLabel},on:{"filter":_vm.applyFilters}})],1)],1),_c('v-row',{staticClass:"mb-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"col-md-6 text-center"},[_c('search-input',{attrs:{"filters":_vm.searchFilters,"active-filter":_vm.activeSearchFilter},on:{"filter-change":_vm.onSearchFilterChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.unreadMeters,"loading":_vm.isUnreadMetersLoading,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"disable-pagination":"","hide-default-footer":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{staticClass:"mt-2",attrs:{"indeterminate":"","rounded":""}}),_c('p',{staticClass:"text-center mt-1"},[_vm._v(" Loading...Please wait ")])]},proxy:true},{key:"body",fn:function(ref){
        var items = ref.items;
return [(_vm.unreadMeters.length > 0)?_c('tbody',_vm._l((items),function(unreadMeter){return _c('tr',_vm._g({key:unreadMeter.id,class:{ clickable: _vm.clickableRows }},_vm.clickableRows ? { click: function () { return _vm.onUnreadMeterClick(unreadMeter.id); } } : {}),[(_vm.clickableRows)?_c('td',[(unreadMeter.user)?_c('span',{staticClass:"primary--text font-weight-medium"},[_vm._v(_vm._s(unreadMeter.user.account_number))]):_vm._e()]):_vm._e(),_c('td',[_vm._v(_vm._s(unreadMeter.meter.number))]),(_vm.clickableRows)?_c('td',[(unreadMeter.user)?_c('span',{staticClass:"primary--text font-weight-medium"},[_vm._v(_vm._s(unreadMeter.user.name))]):_vm._e()]):_vm._e(),_c('td',[_vm._v(_vm._s(_vm._f("formatMonth")(unreadMeter.month)))]),_c('td',[_vm._v(_vm._s(unreadMeter.reason))]),_c('td',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"ma-2":"","text":"","icon":""},on:{"click":function($event){return _vm.editMeter(unreadMeter)}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"ma-2":"","text":"","icon":""},on:{"click":function($event){return _vm.showDeleteMeterDialog(unreadMeter.id)}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)])}),0):_c('tbody',[_c('tr',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isUnreadMetersLoading),expression:"!isUnreadMetersLoading"}]},[_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":_vm.headers.length}},[_vm._v(" No meter unreadMeters found ")])])])]}}])}),_c('v-divider'),_c('data-table-pagination',{attrs:{"page":_vm.pagination.page,"page-count":_vm.pagination.pageCount},on:{"page-change":_vm.onPageChange,"items-per-page-change":_vm.onItemsPerPageChange}})],1),_c('add-unread-meter-dialog',{attrs:{"show-dialog":_vm.showAddMeterDialog,"unread-meter":_vm.meterReading},on:{"close":function($event){return _vm.closeAddMeterDialog()},"unread-meter-added":_vm.onUnreadMeterAdded}}),_c('confirm-dialog',{attrs:{"show-dialog":_vm.showConfirmDeleteDialog,"is-agree-button-loading":_vm.isConfirmDeleteDialogButtonLoading,"agree-text":'Delete'},on:{"cancel":function($event){_vm.showConfirmDeleteDialog = false},"agree":function($event){return _vm.deleteUnreadMeter()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }